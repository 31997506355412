/* eslint-disable */
import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "../reducers/index";
import thunk from 'redux-thunk';
// const store = createStore(rootReducer);


let composeEnhancers;
if (process.env.NODE_ENV !== 'production') {
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
} else {
    composeEnhancers = compose;
}

const store = createStore(rootReducer, 
    composeEnhancers(applyMiddleware(thunk)));

export default store;

