/* eslint-disable */

// Wells
export const SELECTED_WELLS = "SELECTED_WELLS";
export const SET_WELLS = "SET_WELLS";
export const REMOVE_WELLS = "REMOVE_WELLS";
export const WELLS = "WELLS";

// Curve
export const ADD_CURVES = "ADD_CURVES";
export const REMOVE_CURVES = "REMOVE_CURVES";