import isEqual from 'lodash.isequal';
import React, { Component } from 'react';
import "./DlisMetaparametersViewer.css"

interface DlisMetaparametersViwerProps{
    dlisMetadata:any;
    onChange:any;
}

interface CheckedFrame{
    wellName:string;
    fileName:string;
    frameName:string;
}

interface DlisMetaparametersViwerState {
    selectedFrames:CheckedFrame[];
    currentFileView: string;
    currentLogicalFileView: string;
    path:any;
}

export default class DlisMetaparametersViwer extends Component<DlisMetaparametersViwerProps, DlisMetaparametersViwerState> {

    constructor(props:DlisMetaparametersViwerProps){
        super(props);
        this.state = {
            selectedFrames:[],
            currentFileView:Object.keys(this.props.dlisMetadata)[0],
            currentLogicalFileView:"",
            path:{},
        }
    }

    componentDidMount(): void {
        let frames:CheckedFrame[] = [];
        // Object.keys(this.props.dlisMetadata).forEach((fileName:string)=>{
        //     return Object.keys(this.props.dlisMetadata[fileName]).forEach((wellName)=>{
        //         return Object.keys(this.props.dlisMetadata[fileName][wellName]).forEach((logicalFile)=>{
        //             return this.props.dlisMetadata[fileName][wellName][logicalFile].forEach((frame:any)=>{
        //                 return frames.push({wellName:wellName,frameName:`${wellName},${logicalFile},${frame.name}`, fileName:fileName})
        //             })
        //         })
        //     })
        // })
        this.setState({selectedFrames:frames},()=>{this.props.onChange(frames)});
    }

    componentDidUpdate(prevProps: Readonly<DlisMetaparametersViwerProps>, prevState: Readonly<DlisMetaparametersViwerState>, snapshot?: any): void {
        // if(!isEqual(this.props,prevProps)){
        //     let frames:CheckedFrame[] = [];
        //     Object.keys(this.props.dlisMetadata).forEach((fileName:string)=>{
        //         return Object.keys(this.props.dlisMetadata[fileName]).forEach((wellName)=>{
        //             return Object.keys(this.props.dlisMetadata[fileName][wellName]).forEach((logicalFile)=>{
        //                 return this.props.dlisMetadata[fileName][wellName][logicalFile].forEach((frame:any)=>{
        //                     return frames.push({wellName:wellName,frameName:`${wellName},${logicalFile},${frame.name}`, fileName:fileName})
        //                 })
        //             })
        //         })
        //     })
        //     this.setState({selectedFrames:frames},()=>{this.props.onChange(frames)});
        // }
        if(!isEqual(this.props.dlisMetadata,prevProps.dlisMetadata)){
            let path: {[index: string]:any} = {} = this.state.path;
            Object.keys(this.props.dlisMetadata).forEach((fileName)=>{
                if(!Object.keys(path).includes(fileName)){
                    path[fileName] = {}
                    let well_name:string = Object.keys(this.props.dlisMetadata[fileName])[0];
                    path[fileName][well_name]={};
                    const logicalFile = Object.keys(this.props.dlisMetadata[fileName][well_name])[0];
                    path[fileName][well_name][logicalFile] = this.props.dlisMetadata[fileName][well_name][logicalFile][0].name;
                }
            });
            this.setState({currentFileView:Object.keys(this.props.dlisMetadata)[0],path:path},()=>{
                this.setData()
            })
        }
    }

    setFrame =(fileName:string, wellName:string, frameName:string, checked:boolean)=>{
        let frames = this.state.selectedFrames;
        if(checked){
            frames.push({wellName:wellName,frameName:frameName,fileName:fileName});
        }
        else{
            frames = frames.filter((frame)=>frame.frameName != frameName);
        }
        this.setState({selectedFrames:frames},()=>{this.props.onChange(frames)});
    }

    selectAll(checked:boolean){
        let frames:CheckedFrame[] = [];
        if(checked){
            Object.keys(this.props.dlisMetadata).forEach((fileName:string)=>{
                return Object.keys(this.props.dlisMetadata[fileName]).forEach((wellName)=>{
                    return Object.keys(this.props.dlisMetadata[fileName][wellName]).forEach((logicalFile)=>{
                        return this.props.dlisMetadata[fileName][wellName][logicalFile].forEach((frame:any)=>{
                            return frames.push({wellName:wellName,frameName:`${wellName},${logicalFile},${frame.name}`, fileName:fileName})
                        })
                    })
                })
            })
        }
        else{
            frames = [];
        }
        this.setState({selectedFrames:frames},()=>{this.props.onChange(frames)});
    }

    detectedFrameChecked(wellName:string, frameName:string){
        let frames = this.state.selectedFrames;
        const size = frames.filter((frame)=>{return frame.frameName == frameName && frame.wellName == wellName}).length;
        return size > 0;
    }

    // render(): React.ReactNode {
    //     return(
    //         <>
    //             <table style={{backgroundColor:"white", borderRadius:"20px", textAlign:"center"}}>
    //                 <thead>
    //                     <tr>
    //                         <th><input type="checkbox" onChange={(event)=>{this.selectAll(event.target.checked)}} /></th>
    //                         <th>File Name</th>
    //                         <th>Name in file</th>
    //                     </tr>
    //                 </thead>
    //                 <tbody>
    //                     {
    //                         Object.keys(this.props.dlisMetadata).map((fileName:string)=>{
    //                             return Object.keys(this.props.dlisMetadata[fileName]).map((wellName)=>{
    //                                 return Object.keys(this.props.dlisMetadata[fileName][wellName]).map((logicalFile)=>{
    //                                     return this.props.dlisMetadata[fileName][wellName][logicalFile].map((frame:any)=>{
    //                                         const frameName = `${wellName},${logicalFile},${frame.name}`
    //                                         return (
    //                                             <tr>
    //                                                 <td><input type="checkbox" checked={this.detectedFrameChecked(wellName,frameName)} onChange={(event)=>{ this.setFrame(fileName,wellName,frameName,event.target.checked) }} /></td>
    //                                                 <td>{fileName}</td>
    //                                                 <td>{frameName}</td>
    //                                             </tr>
    //                                         )
    //                                     })
    //                                 })
    //                             })
    //                         })
    //                     }
    //                 </tbody>
    //             </table>
    //         </>
    //     )
    // }

    changeFile = (fileName:string) => {
        this.setState({currentFileView:fileName})
    }

    selectLogicalFile = (logicalFile:string) =>{
        this.setState({currentLogicalFileView:logicalFile})
    }

    addPathFrame = (frame:string) =>{
        let path = this.state.path;
        delete path[this.state.currentFileView];
        path[this.state.currentFileView] = {};
        path[this.state.currentFileView][Object.keys(this.props.dlisMetadata[this.state.currentFileView])[0]] = {};
        path[this.state.currentFileView][Object.keys(this.props.dlisMetadata[this.state.currentFileView])[0]][this.state.currentLogicalFileView] = frame;
        this.setState({path:path},()=>{
            this.setData();
        });
    }

    setData = () =>{
        let frames:any = [];
        const path = this.state.path;
        Object.keys(path).map((fileName)=>{
            const wellName = Object.keys(path[fileName])[0]
            const logicalFile = Object.keys(path[fileName][wellName])[0]
            const frame = path[fileName][wellName][logicalFile]
            frames.push({wellName:wellName,frameName:`${wellName}`, fileName:fileName, logicalFile:logicalFile, frame:frame})
        })
        this.props.onChange(frames);
    }

    logicalFileIsChecked = (logicalFileName:string) =>{
        let path = this.state.path;
        if(path[this.state.currentFileView]){
            return path[this.state.currentFileView][Object.keys(this.props.dlisMetadata[this.state.currentFileView])[0]][logicalFileName];
        }
    }

    frameIsChecked = (frame:string) =>{
        let path = this.state.path;
        if(path[this.state.currentFileView]){
            if(path[this.state.currentFileView]){
                return path[this.state.currentFileView][Object.keys(this.props.dlisMetadata[this.state.currentFileView])[0]][this.state.currentLogicalFileView] == frame;
            }
        }
    }

    getFrameList = () => {
        if(this.props.dlisMetadata[this.state.currentFileView][Object.keys(this.props.dlisMetadata[this.state.currentFileView])[0]][this.state.currentLogicalFileView]){
            return(
                <div className='col-6 listView'>
                    <div className='header'>Frame Name</div>
                    {
                        this.props.dlisMetadata[this.state.currentFileView][Object.keys(this.props.dlisMetadata[this.state.currentFileView])[0]][this.state.currentLogicalFileView].map((frameName:any)=>{
                            return(
                                <div>
                                    <div className={`col ${this.frameIsChecked(frameName.name)?'checked':""}`} onClick={(event)=>{this.addPathFrame(frameName.name)}}>{frameName.name}</div>
                                </div>
                            )
                        })
                    }
                </div>
            )
        }
        return(
            <div className='col-6 listView'>
                <div className='header'>Frame Name</div>
            </div>
        )
    }

    render(): React.ReactNode {
        return(
            <React.Fragment>
                <div className='row' style={{width:"100%"}}>
                    <div className='col-3 listView'>
                        <div className='header'>Files Names</div>
                        {Object.keys(this.props.dlisMetadata).map((fileName:string)=>{
                            return(
                                <div >
                                    <div className={`col ${fileName == this.state.currentFileView?'selected':''}`} onClick={(event)=>{this.changeFile(fileName)}}>{fileName}</div>
                                </div>
                            )
                        })}
                    </div>
                    <div className='col'>
                        { this.props.dlisMetadata[this.state.currentFileView] && (
                            <div className='col'>
                                <div className='row'>
                                    <div className='col-6 listView'>
                                        <div className='header'>Logical File Name</div>
                                        {
                                            Object.keys(this.props.dlisMetadata[this.state.currentFileView][Object.keys(this.props.dlisMetadata[this.state.currentFileView])[0]]).map((logicalFileName)=>{
                                                return(
                                                    <div>
                                                        <div className={`col ${logicalFileName == this.state.currentLogicalFileView?'selected':''} ${this.logicalFileIsChecked(logicalFileName)?' checked':""}`} onClick={(event)=>{this.selectLogicalFile(logicalFileName)}}>{logicalFileName}</div>
                                                        
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    {this.getFrameList()}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </React.Fragment>
        )
    }

}