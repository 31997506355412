import React, { Component } from 'react';
import "../../assets/main.css"
import { IoIosArrowForward } from "react-icons/io";

import { EventNames } from "../../utils/enums/EventNames";
// import ViewTabsComponent from '../../components/Tabs/ViewTabsComponent';
import ConfigTabsComponent from '../../components/Tabs/ConfigTabsComponent';
import DashboardNavbar from './DashboardNavbar';

interface DashboardProps{
    children:any,
    currentTabName:string,
    subTab:string
}

interface DashboardState {
    height: string;
    sideBarIsOpen: boolean;
  }


export default class Dashboard extends Component<DashboardProps,DashboardState>{

    resizeEventName: string = EventNames.resizeEvent;

    constructor(props: any){
        super(props)
        this.state = {
          height: '100vh',
          sideBarIsOpen: true
        };
    }

    componentDidMount() {
        // window.addEventListener("beforeunload", function (e) {
        //   e.preventDefault();
        //   // Colocar aqui o evento de saída do usuário
        //   console.log('O evento beforeunload foi disparado.');
        //   // e.returnValue = 'Help Leon!!';
        // });
        document.addEventListener(this.resizeEventName, this.resizeHandleEvent);
    }
    
    componentWillUnmount() {
        document.removeEventListener(this.resizeEventName, this.resizeHandleEvent);
    }

    resizeHandleEvent = (event: any) => {
        if (event.type !== this.resizeEventName){
          return;
        }
    
        if(!event.detail.triggerEvent){
          return;
        }
    
        var rowsCount = event.detail.rows;  
        var numTables = event.detail.numFilesSelected;
    
        var mapViewElementHeight = (rowsCount * 40.8 + 45.2 + 38.4 + 21.6*2 + 24 + 100) * numTables +170;
        
        const height = `${mapViewElementHeight}px`;
        this.setState({
          height: height,
        });
        
    };
    
    homeButton = () =>{
        return (
            <div className="homeButton">
              <button className='home-btn' onClick={()=>{this.goToProjectsPage()}}>
                home
              </button>
            </div>
        )
    }

    goToProjectsPage = () =>{
        window.location.href = '/projects';
    }


    // TODO: Review this structure
    render(): React.ReactNode {
        // console.log("Renderizou aqui!")
        const sidebarWidth = 300;

        return (
            <React.Fragment>
                    <div className="page-container">
                        <div className={this.state.sideBarIsOpen? "sidebar-col sidebar-open" : "sidebar-col"} style={this.state.sideBarIsOpen? {width:`${sidebarWidth}px`, marginLeft: "0"} : {width:`${sidebarWidth}px`, marginLeft: `-${sidebarWidth}px`}}>
                            <ConfigTabsComponent />
                            <button onClick={()=>this.setState({sideBarIsOpen: !this.state.sideBarIsOpen })} className={this.state.sideBarIsOpen? "sidebar-toggle is-open" : "sidebar-toggle"}>
                                <IoIosArrowForward />
                            </button>
                        </div>
                        <div className="main-col" style={this.state.sideBarIsOpen? {width:`calc(100% - ${sidebarWidth}px)`} : {width:"100%"}}>
                            {this.homeButton()}
                            <section id="main-tabs">
                                <DashboardNavbar currentTab={this.props.currentTabName}/>
                                <div className="tab-content" id="nav-tab-map-view-component">
                                    <div
                                        className={`tab-pane fade show active`}
                                        id="Map-View"
                                        role="tabpanel"                
                                    >
                                        {this.props.children}
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
            </React.Fragment>
        )
    }
}
