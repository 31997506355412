import * as types from "../constants/wells";
import * as miscTypes from "../constants/misc";

// TODO: Transform this script in Typescript
const initialState = {
    wellsInfos: {}
}

export default function wells(state=initialState, actions){
    switch(actions.type){
        case types.SET_WELLS:
            return {
                ...state,
                wellsInfos:actions.payload,
            }
        case miscTypes.RESET_STORE:{
            return {
                wellsInfos: {},
            }

        }
        default:
            break;
    }
    return state
}