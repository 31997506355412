import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import { getToken } from '../../utils/helpers/TokenHelper';
import HomePage from '../../pages/Home/HomePage';
import LoginPage from '../../pages/Login/LoginPage';
import SignUpPage from '../../pages/Login/SignUpPage';

import UserProjects from '../../pages/UserProjects/UserProjects';
import Dashboard from '../../pages/Dashboard/Dashboard';
import { ViewTypeTabName } from '../../utils/enums/ViewTypeTabName';
import WellViewTabComponent from '../Tabs/WellViewTabComponent/WellViewTabComponent';
import CorrelationsViewTabComponent from '../Tabs/CorrelationsViewTabComponent/CorrelationsViewTabComponent';
import WellHeadsComponent from '../Tabs/ProcessTabComponent/WellHeadsComponent';
import WellLogsComponent from '../Tabs/ProcessTabComponent/WellLogsComponent';
import WellMarkersExportComponent from '../Tabs/ProcessTabComponent/WellMarkersExportComponent';
import WellMarkersRemoveComponent from '../Tabs/ProcessTabComponent/WellMarkersRemoveComponent';
import WellRemoveComponent from '../Tabs/ProcessTabComponent/WellRemoveComponent';
import WellMarkersComponent from '../Tabs/ProcessTabComponent/WellMarkersComponent';
import WellPathsComponent from '../Tabs/ProcessTabComponent/WellPathsComponent';
import AutomatedCorrelationComponent from '../Tabs/ProcessTabComponent/AutomatedCorrelationPages/AutomatedCorrelationComponent';
import CorrelationsRemoveComponent from '../Tabs/ProcessTabComponent/CorrelationsRemoveComponent';
import { ActionsTabTypes } from '../../utils/enums/ActionsTabTypes';

class RouteComponent extends Component {
  isAuthenticated = () => {
    var token = getToken();
    if(token){
      return token;
    } else {
      return null;
    }
  } 

  getRouter = () => {
    if(!this.isAuthenticated()){
      return (
        <BrowserRouter>
          <Switch>
            <Route path="/signup" component={SignUpPage} />  
            <Route path="/*" component={LoginPage} />                  
          </Switch>
        </BrowserRouter>
      )
    } else {
      return (
        <BrowserRouter>
          <Switch>
            <Route path="/projects" component={UserProjects} />
            <Route path="/dashboard" component={Dashboard} children={<Dashboard children={<div>DashBoard</div>} currentTabName={""} subTab={''} />} />
            <Route path="/wellview" component={Dashboard} children={<Dashboard children={<WellViewTabComponent />} currentTabName={ViewTypeTabName.WELL_VIEW} subTab={''} />} />
            <Route path="/correlations" component={Dashboard} children={<Dashboard children={<CorrelationsViewTabComponent />} currentTabName={ViewTypeTabName.CORRELATION_VIEW} subTab={''} />} />
            <Route path="/process" component={Dashboard} children={<Dashboard children={<div></div>} currentTabName={ViewTypeTabName.PROCESS} subTab={''} />} />
            <Route path="/" component={HomePage} />
            <Route path="/login" component={LoginPage} />
            <Route path="/signup" component={SignUpPage} />
          </Switch>
        </BrowserRouter>
      )
    }
  }

  /**
   * TODO: Create a process view and add this factory inside view.
   */
  getProcessComponentView = (subProcessTab:string) =>{

    switch(subProcessTab){
      case ActionsTabTypes.WELLHEADS:
        return <WellHeadsComponent />;
      case ActionsTabTypes.WELLLOGS:
        return <WellLogsComponent />;
      case ActionsTabTypes.WELLMARKERSEXPORT:
        return <WellMarkersExportComponent />;
      case ActionsTabTypes.WELLMARKERSREMOVE:
        return <WellMarkersRemoveComponent />;
      case ActionsTabTypes.WELLSREMOVE:
        return <WellRemoveComponent />;
      case ActionsTabTypes.WELLMARKERS:
        return <WellMarkersComponent />;
      case ActionsTabTypes.WELLPATHS:
        return <WellPathsComponent />;
      case ActionsTabTypes.AUTOMATED_CORRELATION:
        return <AutomatedCorrelationComponent />;
      case ActionsTabTypes.DELETECORRELATIONS:
        return <CorrelationsRemoveComponent />;
      default:
        return <div>Select a process in Data Tab</div>
    }
  }

  render() {
    return (
      this.getRouter()
    );
  }

}

export default RouteComponent;
