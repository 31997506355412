import React, { Component } from 'react';
import { tabURL, viewNames, ViewTypeTabName } from '../../utils/enums/ViewTypeTabName';

interface DashboardNavbarProps{
    currentTab:string
}
interface DashboardNavbarState{}

export default class DashboardNavbar extends Component<DashboardNavbarProps,DashboardNavbarState>{


    handleTabClick = (tabName:ViewTypeTabName) => {
        window.location.href = tabURL(tabName)
    }

    render(): React.ReactNode {
        return(
            <React.Fragment>
                <nav>
                    <div className="nav nav-tabs nav-fill" id="nav-tab-map-view" role="tablist">
                        {viewNames.map((viewName:ViewTypeTabName)=>{
                            return (
                                <a
                                    className={`nav-item nav-link ${this.props.currentTab === viewName ? 'active' : ''}`}
                                    id="well-view-tab"
                                    onClick={() => this.handleTabClick(viewName)}
                                    aria-controls="Well View"
                                    aria-selected={this.props.currentTab === viewName}
                                >
                                    {viewName}
                                </a>
                            )
                        })}
                    </div>
                </nav>
            </React.Fragment>
        )
    }

}