export enum ViewTypeTabName {
    MAP_VIEW = 'Map View',
    WELL_VIEW = 'Well View',
    TABLE_VIEW = 'Table View',
    CORRELATION_VIEW = 'Correlation View',
    PROCESS = 'Process',
    EXPORT_DATA = 'Export data',
    REMOVE_DATA = 'Remove data',
    AUTOMATED_CORRELATION = 'Automated correlation'
}

export const viewNames:ViewTypeTabName[] = [
    ViewTypeTabName.WELL_VIEW,
    ViewTypeTabName.CORRELATION_VIEW,
    ViewTypeTabName.PROCESS,
]

export function tabURL(tabName:ViewTypeTabName){
    switch(tabName){
        case ViewTypeTabName.WELL_VIEW:
            return "/wellview"
        case ViewTypeTabName.CORRELATION_VIEW:
            return "/correlations"
        case ViewTypeTabName.PROCESS:
            return "/process/"
        default:
            return "/"
    }
}