/* eslint-disable */
import { combineReducers } from "redux";
// import { reducer as toastr } from "react-redux-toastr";

import wells from "./wells";

export default combineReducers({
  wells: wells,
});

